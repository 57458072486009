import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { Suspense, lazy } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import Loader from "./Component/Loader";
import PromoCode from "./Component/PromoCode";
import Login from "./Component/Login";
import BookModule from "./Component/BookModule";
import DashboardLayout from "./Component/DashboardLayout";
import Dashboard from "./Component/Dashboard";
import PartnerDashboard from "./Component/PartnerDashboard";
import Master from "./Component/Master";
import SubscriptionPrice from "./Component/SubscriptionPrice";
import Topic from "./Component/Topic";
import Question from "./Component/Question";
import ApproveRejectQuestion from "./Component/approve-reject-question";
import NotFound from "./Component/NotFound";
import Advertisement from "./Component/Advertisement";
import Shop from "./Component/Shop";
import BookReport from "./Component/BookReport";
import UserReport from "./Component/UserReport";
import PaperGeneration from "./Component/PaperGeneration";
import UserAccess from "./Component/UserAccess";
import { getDecryptedDataFromLocalStorage } from "./Api/encryption";
import QuestionAccess from "./Component/QuestionAccess";
import OrderComment from "./Component/OrderComment";
import ImageUpload from "./Component/ImageUpload";
import BatchBenifits from "./Component/BatchBenifits";
const ManageBoard = lazy(() => import("./Component/ManageBoard"));
const EditQuestion = lazy(() => import("./Component/EditQuestion"));
const Notification = lazy(() => import("./Component/Notification"));
const ViewProducts = lazy(() => import("./Component/ViewProducts"));
const AddNewHistory = lazy(() => import("./Component/AddNewHistory"));
const RegisterUser = lazy(() => import("./Component/RegisterUser"));
const Notice = lazy(() => import("./Component/Notice"));
const RTO = lazy(() => import("./Component/RTO"));
const Report = lazy(() => import("./Component/Report"));
const StudentApp = lazy(() => import("./Component/StudentApp"));
const StandardApp = lazy(() => import("./Component/StandardApp"));
const Profile = lazy(() => import("./Component/Profile"));
const BookComment = lazy(() => import("./Component/BookComment"));
const UserComment = lazy(() => import("./Component/UserComment"));
const Equation = lazy(() => import("./Component/Equation"));
const Maths = lazy(() => import("./Component/Maths"));
const ProtectedRoute = lazy(() => import("./ProtectedRoute/ProtectedRoute"));
const PublicRoute = lazy(() => import("./public-route/publice-route"));
const ForgotPassword = lazy(() => import("./Component/ForgotPassword"));
const Verify = lazy(() => import("./Component/Verify"));
const ResetPassword = lazy(() => import("./Component/ResetPassword"));
const Preference = lazy(() => import("./Component/Preference"));

function App() {
  const data = getDecryptedDataFromLocalStorage("userData");
  const jsonObjects = data?.access?.split("},{");
  // Add missing curly braces to each JSON object
  const jsonObjectsWithBraces = jsonObjects?.map((obj) => {
    if (obj.startsWith("{")) {
      return obj + "}";
    } else if (obj.endsWith("}")) {
      return "{" + obj;
    } else {
      return "{" + obj + "}";
    }
  });

  const access = Array.isArray(jsonObjectsWithBraces)
    ? jsonObjectsWithBraces.map((obj) => {
      try {
        return JSON.parse(obj);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        return null; // or handle the error as needed
      }
    })
    : [];

  console.log("EEEEEaccess", access, data);

  // const promocodeObject = {
  //   name: "Promocode",
  //   view: true,
  //   add: true,
  //   edit: true,
  //   delete: true,
  // };
  // const BookHistory = {
  //   name: "Book History",
  //   view: true,
  //   add: true,
  //   edit: true,
  //   delete: true,
  // };
  // access.push(promocodeObject);
  // access.push(BookHistory);
  // const Semester = {
  //   name: "Semester",
  //   view: true,
  //   add: true,
  //   edit: true,
  //   delete: true,
  // };
  // access.push(Semester);

  const questionObject = access?.find(
    (item) => item?.name === "Question" && item?.view
  );

  // if (questionObject) {
  //   const approveQuestionExists = access?.some(
  //     (item) => item?.name === "Approve Question"
  //   );

  //   if (!approveQuestionExists) {
  //     access.push({
  //       name: "Approve Question",
  //       view: questionObject?.view,
  //       add: questionObject?.add,
  //       edit: questionObject?.edit,
  //       delete: questionObject?.delete,
  //     });
  //   }
  // }

  // const AdvertisementImagesObject = access?.find(
  //   (item) => item?.name === "Master" && item?.view
  // );

  // if (AdvertisementImagesObject) {
  //   const AdvertisementImagesExists = access?.some(
  //     (item) => item?.name === "Advertisement Images"
  //   );

  //   if (!AdvertisementImagesExists) {
  //     access.push({
  //       name: "Advertisement Images",
  //       view: AdvertisementImagesObject?.view,
  //       add: AdvertisementImagesObject?.add,
  //       edit: AdvertisementImagesObject?.edit,
  //       delete: AdvertisementImagesObject?.delete,
  //     });
  //   }
  // }

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/login" element={<PublicRoute component={Login} />} />
            <Route path="/book" element={<BookModule />} />
            <Route path="/*" element={<NotFound />} />
            <Route path="/imageupload" element={<ImageUpload />} />
            <Route
              path="/forgotpassword"
              element={<PublicRoute component={ForgotPassword} />}
            />
            <Route
              path="/verify"
              element={<PublicRoute component={Verify} />}
            />
            <Route
              path="/resetpassword"
              element={<PublicRoute component={ResetPassword} />}
            />
            <Route path="/" element={<PublicRoute component={Login} />} />
            {/* Dashboard */}
            {data?.type !== "partner" ? (
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute
                    access={access}
                    Component={DashboardLayout}
                    content={<Dashboard access={access} />}
                    value={"Dashboard"}
                  />
                }
              />
            ) : (
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute
                    access={access}
                    Component={DashboardLayout}
                    content={<PartnerDashboard access={access} />}
                    value={"Dashboard"}
                  />
                }
              />
            )}
            {/* Dashboard */}
            {/* Master */}
            {/* <Route
              path="/master/school-type"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Master access={access} />}
                />
              }
            /> */}
            <Route
              path="/master/board"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Master access={access} />}
                  value={"Master"}
                />
              }
            />
            <Route
              path="/master/medium"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Master access={access} />}
                  value={"Master"}
                />
              }
            />
            <Route
              path="/master/standard"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Master access={access} />}
                  value={"Master"}
                />
              }
            />
            <Route
              path="/master/semester"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Master access={access} />}
                  value={"Master"}
                />
              }
            />
            <Route
              path="/master/subject"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Master access={access} />}
                  value={"Master"}
                />
              }
            />
            <Route
              path="/master/chapter"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Master access={access} />}
                  value={"Master"}
                />
              }
            />
            <Route
              path="/master/teacher"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Master access={access} />}
                  value={"Master"}
                />
              }
            />
            <Route
              path="/master/reference-book"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Master access={access} />}
                  value={"Master"}
                />
              }
            />
            <Route
              path="/master/question-type"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Master access={access} />}
                  value={"Master"}
                />
              }
            />
            <Route
              path="/master/random-generate-notice"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Master access={access} />}
                  value={"Master"}
                />
              }
            />
            <Route
              path="/master/add-sound"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Master access={access} />}
                  value={"Master"}
                />
              }
            />
            <Route
              path="/master/generate-qr-code"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Master access={access} />}
                  value={"Master"}
                />
              }
            />
            {/* <Route
              path="/master/file-image"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Master access={access} />}
                />
              }
            />
            <Route
              path="/master/payment-pop-up-info"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Master access={access} />}
                />
              }
            />
            <Route
              path="/master/3rd-party-ads-youth"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Master access={access} />}
                />
              }
            />
            <Route
              path="/master/random-generate-notice/manage-board"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<ManageBoard access={access} />}
                />
              }
            /> */}
            {/* Master */}
            {/* promo code */}
            <Route
              path="/promo-code"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<PromoCode access={access} />}
                  value={"Promocode"}
                />
              }
            />
            {/* promo code */}
            {/* Subscription Price */}
            {/* <Route
              path="/subscription-price"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<SubscriptionPrice access={access} />}
                />
              }
            /> */}
            {/* Subscription Price */}
            {/* Topic */}
            <Route
              path="/topic"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Topic access={access} />}
                  value={"Topic"}
                />
              }
            />
            {/* Topic */}
            {/* Question */}
            <Route
              path="/question"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Question access={access} />}
                  value={"Question"}
                />
              }
            />
            <Route
              path="/question/editquestion/:id"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<EditQuestion access={access} />}
                  value={"Question"}
                />
              }
            />
            {/* Question */}
            {/* Approve Question */}
            <Route
              path="/all-question/pending-question"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<QuestionAccess access={access} />}
                  value={"Approve Question"}
                />
              }
            />
            <Route
              path="/all-question/approve-question"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<QuestionAccess access={access} />}
                  value={"Approve Question"}
                />
              }
            />
            <Route
              path="/all-question/question-report"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<QuestionAccess access={access} />}
                  value={"Approve Question"}
                />
              }
            />
            {/* Approve Question */}
            {/* Advertisement */}
            <Route
              path="/advertisement/learning-images"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Advertisement access={access} />}
                  value={"Advertisement"}
                />
              }
            />
            <Route
              path="/advertisement/paper-generate-banners"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Advertisement access={access} />}
                  value={"Advertisement"}
                />
              }
            />
            <Route
              path="/advertisement/batch-banners"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Advertisement access={access} />}
                  value={"Advertisement"}
                />
              }
            />
            <Route
              path="/advertisement/live-batch-banners"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Advertisement access={access} />}
                  value={"Advertisement"}
                />
              }
            />
            {/* Advertisement */}
            {/* Notification */}
            <Route
              path="/notification"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Notification access={access} />}
                  value={"Notification"}
                />
              }
            />
            {/* Notification */}
            {/* shop */}
            <Route
              path="/shop/books"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Shop access={access} />}
                  value={data?.type === "partner" ? "Product" : "Shop"}
                />
              }
            />
            <Route
              path="/shop/books/view-products"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<ViewProducts access={access} />}
                  value={data?.type === "partner" ? "Product" : "Shop"}
                />
              }
            />
            <Route
              path="/shop/book-history"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Shop access={access} />}
                  value={data?.type === "partner" ? "Book History" : "Shop"}
                />
              }
            />
            <Route
              path="/shop/book-history/book-comment"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<BookComment access={access} />}
                  value={data?.type === "partner" ? "Book History" : "Shop"}
                  BookComment={true}
                />
              }
            />

            {/* <Route
              path="/shop/set-expiry-date"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Shop access={access} />}
                />
              }
            /> */}
            {/* shop */}

            {/* Add New History */}
            {/* <Route
              path="/addNewHistory/order-history-details"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<AddNewHistory access={access} />}
                />
              }
            /> */}
            {/* Add New History */}
            {/* Register User */}
            {/* <Route
              path="/registered-user/registered-user-comment"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<RegisterUser access={access} />}
                />
              }
            />
            <Route
              path="/registered-user/none-ordered-user"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<RegisterUser access={access} />}
                />
              }
            /> */}
            {/* Register User */}

            {/* Paper */}
            {/* <Route
              path="/paper-generation/random"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<PaperGeneration access={access} />}
                />
              }
            /> */}
            <Route
              path="/paper-generation/auto"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<PaperGeneration access={access} />}
                  value={"Paper"}
                />
              }
            />
             <Route
              path="/paper-generation/auto-flow"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<PaperGeneration access={access} />}
                  value={"Paper"}
                />
              }
            />
             <Route
              path="/paper-generation/blueprint"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<PaperGeneration access={access} />}
                  value={"Paper"}
                />
              }
            />
            <Route
              path="/paper-generation/paper-history"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<PaperGeneration access={access} />}
                  value={"Paper"}
                />
              }
            />
            <Route
              path="/paper-generation/order-history"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<PaperGeneration access={access} />}
                  value={"Paper"}
                />
              }
            />
            <Route
              path="/paper-generation/followup-order"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<PaperGeneration access={access} />}
                  value={"Paper"}
                />
              }
            />
            <Route
              path="/paper-generation/order-history/order-comment"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<OrderComment access={access} />}
                  value={"Paper"}
                />
              }
            />
            <Route
              path="/paper-generation/set-expiry-date"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<PaperGeneration access={access} />}
                  value={"Paper"}
                />
              }
            />

            <Route
              path="/paper-generation/user"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<PaperGeneration access={access} />}
                  value={"Paper"}
                />
              }
            />
              <Route
              path="/paper-generation/batch-user"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<PaperGeneration access={access} />}
                  value={"Paper"}
                />
              }
            />
            <Route
              path="/paper-generation/user-comment"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<UserComment access={access} />}
                  value={"Paper"}
                />
              }
            />

            <Route
              path="/paper-generation/notice"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<PaperGeneration access={access} />}
                  value={"Paper"}
                />
              }
            />

            <Route
              path="/paper-generation/help-section"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<PaperGeneration access={access} />}
                  value={"Paper"}
                />
              }
            />

            <Route
              path="/paper-generation/plan-info"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<PaperGeneration access={access} />}
                  value={"Paper"}
                />
              }
            />
            <Route
              path="/paper-generation/batch-benifits"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<PaperGeneration access={access} />}
                  value={"Paper"}
                />
              }
            />
             <Route
              path="/paper-generation/refund-policy"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<PaperGeneration access={access} />}
                  value={"Paper"}
                />
              }
            />
            

            {/* Paper */}
            {/* RTO */}
            {/* <Route
              path="/RTO"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<RTO access={access} />}
                />
              }
            /> */}
            {/* RTO*/}
            {/* Report */}

            <Route
              path="/report"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<UserReport access={access} />}
                  value={"Report"}
                />
              }
            />

            {/* <Route
              path="/report/question-report"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Report access={access} />}
                />
              }
            />
            <Route
              path="/report/topic-report"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Report access={access} />}
                />
              }
            /> */}
            {/* Report */}
            {/* Student App */}
            {/* <Route
              path="/student-app/app-registered-users"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<StudentApp access={access} />}
                />
              }
            />
            <Route
              path="/student-app/app-registered-users/Preference"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Preference access={access} />}
                />
              }
            />
            <Route
              path="/student-app/aap-registered-user-comment"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<StudentApp access={access} />}
                />
              }
            />
            <Route
              path="/student-app/user-points"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<StudentApp access={access} />}
                />
              }
            />
            <Route
              path="/student-app/redeem-requests"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<StudentApp access={access} />}
                />
              }
            />
            <Route
              path="/student-app/advertisement-images"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<StudentApp access={access} />}
                />
              }
            />
            <Route
              path="/student-app/3rd-party-ads-youth"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<StudentApp access={access} />}
                />
              }
            /> */}
            {/* Student App */}
            {/* Standard App */}
            {/* <Route
              path="/standard-app/notification"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<StandardApp access={access} />}
                />
              }
            />
            <Route
              path="/standard-app/adverstisement"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<StandardApp access={access} />}
                />
              }
            />
            <Route
              path="/standard-app/app-registered-users"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<StandardApp access={access} />}
                />
              }
            />
            <Route
              path="/standard-app/aap-registered-user-comment"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<StandardApp access={access} />}
                />
              }
            /> */}
            {/* Standard App */}
            {/* Access */}
            <Route
              path="/access/admin"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<UserAccess access={access} />}
                  value={"Access"}
                />
              }
            />
            <Route
              path="/access/partner-collab"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<UserAccess access={access} />}
                  value={"Access"}
                />
              }
            />

            {/* Access */}
            {/* Profile */}
            {/* <Route
              path="/profile/user"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Profile access={access} />}
                />
              }
            />
            <Route
              path="/profile/user-detail"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Profile access={access} />}
                />
              }
            />
            <Route
              path="/profile/logout"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Profile access={access} />}
                />
              }
            /> */}
            {/* Profile */}
            {/* Equation */}
            {/* <Route
              path="/equation"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Equation access={access} />}
                />
              }
            />
            <Route
              path="/maths"
              element={
                <ProtectedRoute
                  access={access}
                  Component={DashboardLayout}
                  content={<Maths access={access} />}
                />
              }
            /> */}
            {/* Equation */}
          </Routes>
        </Suspense>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
